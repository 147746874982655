<template>
  <div class="footer">
    <div class="logo"><img src="@/assets/imgs/logo_footer.png" alt=""></div>
    <div class="rights">© Meta-Utopia. All righes reserved</div>
    <div class="followUs">
      <div class="title">FOLLOW US</div>
      <div class="icons">
        <a href="https://t.me/MW_MetaUtopia">
          <img src="@/assets/imgs/icon11.png" alt="">
        </a>
        <a href="https://discord.gg/HK2CRqhPfQ">
          <img src="@/assets/imgs/icon12.png" alt="">
        </a>
        <a href="https://twitter.com/mw_metautopia">
          <img src="@/assets/imgs/icon13.png" alt="">
        </a>
        <a href="https://tttttt.me/Nic_MetaUtopiacs">
          <img src="@/assets/imgs/icon14.png" style="width: 12px;" alt="">
        </a>
        <div style="width: 14px;" @click="openZendesk">
          <img src="@/assets/imgs/icon15.png" alt="">
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="title" style="margin-bottom: 4px;">GET iN TOUCH WITH US</div>
      <div class="content">
        <p>contact@meta-utopia.io</p>
        <p>We'd love to hear your thoughts.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    openZendesk() {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    }
  }
}
</script>

<style lang="less" scoped>
.footer{
  background-color: #12191c;
  padding: 32px 0;
  color: #fff;
  .logo{
    width: 154px;
    margin: 0 auto 8px auto;
  }
  .rights{
    font-family: Montserrat-Regular, Verdana;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .title{
    font-family: Montserrat-Bold, Verdana;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    text-transform: uppercase;
    background-image: -webkit-linear-gradient(top,#0bedad,#0ba191);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8px;
  }
  .followUs{
    margin-bottom: 28px;
    .icons{
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        margin-right: 9px;
        width: 14px;
      }
      a:last-child{
        margin-right: 0;
      }
    }
  }
  .contact{
    .content{
      font-family: Montserrat-Regular, Verdana;
      font-size: 12px;
      line-height: 21px;
    }
  }
}
</style>
