<template>
  <div class="follow">
    <div class="img"><img src="@/assets/imgs/people.png" alt=""></div>
    <div class="text">
      <div class="title">Join metA-utopia</div>
      <div class="content">
        <p>Be part of our Amazing Community and Stay</p>
        <p>Tuned for latest news.</p>
      </div>
      <router-link to="/join">
        <div class="btn2">Follow Us</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Follow"
}
</script>

<style lang="less" scoped>
.follow{
  background-color: #12191c;
  .img{
    width: 229px;
    margin: 0 auto;
  }
  .text{
    color: #000000;
    padding: 17px 0 23px 0;
    background: linear-gradient(180deg, #0BEDAD 0%, #0BA191 100%);
    .title{
      font-family: Montserrat-Bold, Verdana;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
    .content{
      font-family: Montserrat-Regular, Verdana;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>
