var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._m(0),_c('div',{staticClass:"rights"},[_vm._v("© Meta-Utopia. All righes reserved")]),_c('div',{staticClass:"followUs"},[_c('div',{staticClass:"title"},[_vm._v("FOLLOW US")]),_c('div',{staticClass:"icons"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticStyle:{"width":"14px"},on:{"click":_vm.openZendesk}},[_c('img',{attrs:{"src":require("@/assets/imgs/icon15.png"),"alt":""}})])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/imgs/logo_footer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://t.me/MW_MetaUtopia"}},[_c('img',{attrs:{"src":require("@/assets/imgs/icon11.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://discord.gg/HK2CRqhPfQ"}},[_c('img',{attrs:{"src":require("@/assets/imgs/icon12.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://twitter.com/mw_metautopia"}},[_c('img',{attrs:{"src":require("@/assets/imgs/icon13.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://tttttt.me/Nic_MetaUtopiacs"}},[_c('img',{staticStyle:{"width":"12px"},attrs:{"src":require("@/assets/imgs/icon14.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"title",staticStyle:{"margin-bottom":"4px"}},[_vm._v("GET iN TOUCH WITH US")]),_c('div',{staticClass:"content"},[_c('p',[_vm._v("contact@meta-utopia.io")]),_c('p',[_vm._v("We'd love to hear your thoughts.")])])])
}]

export { render, staticRenderFns }